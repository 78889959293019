import * as React from "react"
import { Link, graphql } from "gatsby"
import Helmet from "react-helmet"

import "./Post.scss"
import Social from "./partials/post/Social"
// ______________________________________________________
//
type Props = {
  post: {
    frontmatter: {
      date: string
      path: string
      title: string
    }
    excerpt: string
  }
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ post }) => {
  return (
    <div className="WrapPost">
      <Link className="WrapPostContents" to={`${post.frontmatter.path}`}>
        <div className="PostArticle">
          <div className="PostArticleDate">{post.frontmatter.date}</div>
          <div className="PostArticleTitle">{post.frontmatter.title}</div>
          <div className="PostArticleDescription">{post.excerpt}</div>
        </div>
      </Link>
    </div>
  )
}
// ______________________________________________________
//

// ______________________________________________________
//
export default Component
