import * as React from "react"
import Helmet from "react-helmet"
import { Footer } from "../components/partials/layout/Footer"
// ______________________________________________________
//
const Component: React.FC = props => (
  <div id="wrapper">
    <Helmet
      title="Candy Blog"
      meta={[
        { name: "description", content: "Candy Blog" },
        {
          name: "description",
          content:
            "フリーランス・パラレルワーカーのためのモバイル会計クラウド「Candy」公式ブログ"
        },
        { property: "og:site_name", content: "Candy Blog" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://blog.candy.ac/" },
        { property: "og:title", content: "Candy Blog" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          property: "og:description",
          content:
            "フリーランス・パラレルワーカーのためのモバイル会計クラウド「Candy」公式ブログ"
        },
        {
          property: "og:image",
          content: `https://blog.candy.ac/static/images/opengraph.png`
        }
      ]}
      link={[
        { rel: "icon", type: "image/png", href: "/static/images/icon.png" }
      ]}
    />
    {props.children}
    <Footer />
  </div>
)
// ______________________________________________________
//
export default Component
