import * as React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { BlogPosting, WithContext, Organization } from "schema-dts"

import { IndexQuery } from "../../types/graphql-types"

import Layout from "../layouts"
import Header from "../components/partials/layout/Header"
import Post from "../components/Post"
import "./index.scss"
// ______________________________________________________
//
type Props = {
  data: IndexQuery
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({
  data: {
    allMdx: { edges }
  }
}) => {
  const Posts = edges
    ?.filter(edge => !!edge.node.frontmatter?.date) // You can filter your posts based on some criteria
    .map(edge => <Post key={edge.node.id} post={edge.node} />)
  const modified = edges.map(edge => edge.node.parent?.modifiedTime)
  const candyPublisher = ({
    "@type": "Organization",
    name: "ElevenBack LLC.",
    url: "https://elevenback.co.jp",
    logo: {
      "@type": "ImageObject",
      url: "https://blog.candy.ac/static/images/icon.png",
      width: 60,
      height: 60
    },
    address: {
      "@type": "PostalAddress",
      postalCode: "107-0061",
      addressRegion: "東京都",
      addressLocality: "港区北青山2丁目",
      streetAddress: "7−20 第二猪瀬ビル2階"
    }
  } as any) as Organization
  const candyBlogInfo: WithContext<BlogPosting> = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://blog.candy.ac"
    },
    headline: "Candy Official Blog",
    image: "https://blog.candy.ac/static/images/icon.png",
    author: {
      "@type": "Organization",
      name: "ElevenBack LLC."
    },
    publisher: candyPublisher,
    datePublished: "2019-12-26",
    dateModified: `${modified}`
  }
  return (
    <Layout>
      <Header></Header>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(candyBlogInfo)}
        </script>
      </Helmet>
      <div id="content">
        <div className="WrapIndex">
          <div className="WrapIndexPostPage">{Posts}</div>
        </div>
      </div>
    </Layout>
  )
}
// ______________________________________________________
//
export const indexQuery = graphql`
  query index {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            path
            title
          }
          parent {
            ... on File {
              modifiedTime(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    }
  }
`
// ______________________________________________________
//
export default Component
